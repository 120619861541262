import * as React from 'react';
import { useEffect } from 'react';

function NotFoundPage() {
  useEffect(() => {
    window.location.href = '/nl/404'; // https://www.trooper.be/nl/404 is the Webflow 404 page
  }, []);

  return <>404 Not Found</>;
}

export default NotFoundPage;
